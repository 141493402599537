import { useState, React, useEffect, Suspense, lazy } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getbranchData, handleLogin } from "../../../redux/authentication";
import pps from "../../../images/logo512.png"; //<img src={`${pps}`} style={{ width: "50px" }} />
//import bcrypt from "bcryptjs"; // Use a client-side hashing library like bcryptjs
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Row,
  FormGroup,
  Spinner,
  Label,
  Nav,
  NavItem,
} from "reactstrap";
import { handleError } from "../../../redux/inventory";
import Header from "../../../paupet/Header";
import Home from "./Home";
import { LogIn } from "react-feather";
const PaupetSoft = lazy(() => import("../PaupetSoft"));
// Lazy load the OTP modal
const OtpModal = lazy(() => import("./OTPModal")); // Adjust the path if necessary
const ContactForm = lazy(() => import("./ContactForm")); // Adjust the path if necessary

const Login = ({ setUserRights }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [branch, setBranch] = useState("");
  //const { setUserRights } = useUser();
  const [loginUserRights, setLoginUserRights] = useState("");
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [toggleLogin, setToggleLogin] = useState(false);
  const [otpStatus, setOtpStatus] = useState("NA");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState("");
  const [otpDetails, setOTPDetails] = useState("");

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  const passedAuthenticationHandler = (dataArg) => {
    userLogHandler(dataArg.token);
    dispatch(handleLogin({ ...dataArg, branch }));
    dispatch(getbranchData({ token: dataArg.token, branch }));

    sessionStorage.setItem("token", JSON.stringify(dataArg.token));
    sessionStorage.setItem("branch", JSON.stringify(branch));
    sessionStorage.setItem("username", JSON.stringify(username));
    setLoginUserRights(dataArg.rights);
    sessionStorage.setItem("rights", JSON.stringify(dataArg.rights));
  };

  const handleOtpSubmit = (otp) => {
    // Call API to verify OTP
    fetch(`${process.env.REACT_APP_CRI_API}verify-otp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${data.token}`,
      },
      body: JSON.stringify({
        token: otpDetails.token,
        otp: otp && otp.trim(),
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        // "data": {
        //    "id": "01926742-de09-01da-6ce1-aa539dad05ca",
        //  "method": "sms",
        // "status": "SUCCESSFUL"
        //   }
        //data":{"message":"Verified"}
        if (res?.data?.message === "Verified")
          passedAuthenticationHandler(data); // tricky data , check if needed
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if ((loginUserRights && otpStatus === "NA") || otpStatus === "Successful") {
      setUserRights(loginUserRights);
      // console.log(loginUserRights, data);
      // Handle successful login
      if (branch === "CLT") {
        if (selectedRoute === "que") {
          navigate("/que-sys", {
            replace: true,
            state: { ...data, branch },
          });
          return;
        }
        if (data.rights === "Accounts") {
          navigate("/phmcy-admin", {
            replace: true,
            state: { ...data, branch },
          });
        } else if (data.rights === "Doctor") {
          navigate("/trtmnt", {
            replace: true,
            state: { ...data, branch, username },
          });
        } else if (data.rights === "care") {
          navigate("/ccare", {
            replace: true,
            state: { ...data, branch, username },
          });
        } else if (data.rights === "Admin" || data.rights === "Manager") {
          navigate("/reports", {
            replace: true,
            state: { ...data, branch },
          });
        } else if (data.rights === "Reception") {
          navigate("/crm-clt", {
            replace: true,
            state: { ...data, branch },
          });
        } else if (data.rights === "ITAdmin") {
          navigate("/admin", {
            replace: true,
            state: { ...data, branch },
          });
        } else if (data.rights === "Customer") {
          navigate("/customer_form", {
            replace: true,
            state: { ...data, branch },
          });
        } else if (branch === "UAE" && username === "admin") {
          navigate("/crm", { replace: true, state: { ...data, branch } });
        }
      } else if (branch === "Demo") {
        if (data.rights === "Doctor") {
          navigate("/trtmnt", {
            replace: true,
            state: { ...data, branch, username },
          });
        } else if (selectedRoute === "que") {
          navigate("/que-sys", {
            replace: true,
            state: { ...data, branch },
          });
          return;
        } else {
          navigate("/crm-clt", { replace: true, state: { ...data, branch } });
        }
      } else if (otpStatus !== "Successful") {
        // Handle failed login
        // alert("Invalid credentials");
        // console.log("Invalid credentials");
      }
    }
  }, [setUserRights, loginUserRights, data.token, data.rights, otpDetails]);

  useEffect(() => {
    dispatch(handleError());
  }, [username]);

  useEffect(() => {
    //   if (!latitude && !longitude) {
    getLocation();
    setLoading(false);
    //   }
  }, [latitude, longitude]);

  const getLocationDetails = (latitude, longitude) => {
    const nominatimUrl = `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`;

    fetch(nominatimUrl)
      .then((response) => response.json())
      .then((data) => {
        const city =
          data.address.city || data.address.town || data.address.village;
        const state = data.address.state;
        const country = data.address.country;
        //   console.log(state, country, city);
      })
      .catch((error) => console.error("Error with Nominatim API:", error));
  };

  const isWithinRange = (lat1, lon1, lat2, lon2, radius) => {
    const R = 6371; // Earth's radius in km
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in km
    return distance <= radius;
  };

  const getLocation = () => {
    const storedLocation = sessionStorage.getItem("userLocation");

    if (storedLocation) {
      const { latitude, longitude } = JSON.parse(storedLocation);
      setLatitude(latitude);
      setLongitude(longitude);
      getLocationDetails(latitude, longitude);
      return;
    }
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        // Cache the location in session storage
        sessionStorage.setItem(
          "userLocation",
          JSON.stringify({ latitude, longitude })
        );
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);

        getLocationDetails(latitude, longitude);
      },
      (error) => {
        console.error("Error getting geolocation:", error);
      }
    );
  };

  const userLogHandler = (token) => {
    const timestamp = new Date();

    fetch(`${process.env.REACT_APP_CRI_API}add-userlog?branch=${branch}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: username,
        latitude,
        longitude,
        timestamp,
      }),
    });
  };
  const clearSession = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("branch");
    sessionStorage.removeItem("username");
    sessionStorage.removeItem("rights");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Clear session storage
    clearSession();

    const withinAllowedRange = isWithinRange(
      11.2695802,
      75.7763289,
      // 13.0859244,
      // 77.6496957,
      latitude,
      longitude,
      5
    );
    //11.220213,76.213814 - Sinjin home access
    if (
      !withinAllowedRange &&
      username !== "Anil" &&
      branch === "CLT" &&
      username !== "Paul"
    ) {
      alert("Unauthorized Location");
      return;
    }
    setLoading(true);
    // console.log(branch);
    // temp solution for clt or wrong branch

    if (branch && branch !== "CLT" && branch !== "Demo") {
      alert("Enter branch correctly");
      setLoading(false);
      return;
    }
    if (!branch) {
      alert("Please select a branch");
      setLoading(false);
      return;
    }
    if (!username) {
      alert("Please enter username");
      setLoading(false);
      return;
    }
    if (!password) {
      alert("Please enter password");
      setLoading(false);
      return;
    }
    if (branch && username && password) {
      fetch(`${process.env.REACT_APP_CRI_API}auth`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ branch, username, password }),
      })
        .then((response) => {
          setLoading(false);
          //  console.log(response);
          if (response.error) {
            alert("Invalid Password");
            return;
          }
          return response.json();
        })
        .then((res) => {
          // console.log(res);
          if (res.error) {
            alert("Invalid Credentials");
            return;
          }
          //
          //  error: "Failed to verify password";
          setData(res);
          setLoading(false);
          //otpDetails.token
          if (res.otpSent === true && res.mobileNo) {
            setOTPDetails({
              token: res.otpDetails.token,
              mobile: res.otpDetails.mobileNo,
            });
            toggleModal();
          } else {
            passedAuthenticationHandler(res); // for time being without mobile/OTPs old users
            return;
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  return (
    <>
      <Container
        fluid
        style={{
          background: "linear-gradient(to right, indigo, lightblue 50%, white)", // Indigo on left, light blue center, white on right
          width: "100vw", // Full viewport width
          height: "100vh", // Full viewport height

          alignItems: "center",
        }}
      >
        <Header>
          <Nav>
            <img
              src={`${pps}`}
              style={{ width: "50px" }}
              className="p-1 mb-1"
            />
            <NavItem className="pt-1">
              <span>
                <b>PAUPET SOFT</b>
              </span>
            </NavItem>
          </Nav>
          <div style={{ flexGrow: 1 }}></div>
          <div
            onClick={() => {
              // console.log(latitude, longitude);
              if (!latitude && !longitude) {
                alert("Kindly share location before login for security");
                return;
              }

              setToggleLogin(!toggleLogin);
            }}
            style={{ cursor: "pointer" }}
          >
            <span className="me-2">Login</span>
            <LogIn />
          </div>
        </Header>

        <Row className="justify-content-start">
          <Col className="pt-4 pe-3">
            <Row className="text-center mt-5 pt-5">
              <Col
                style={{
                  position: "absolute", // Make the position fixed
                  top: "50%", // Center it vertically
                  left: "50%", // Center it horizontally
                  transform: "translate(-50%, -50%)", // Adjust the positioning
                  textAlign: "center", // Center the text
                }}
              >
                <img src={`${pps}`} style={{ width: "100px" }} />
                <h1 style={{ color: "#FFFFFF", fontWeight: "bold" }}>
                  Welcome to PAUPET SOFT
                </h1>
                <p className="lead" style={{ color: "#C0C0C0" }}>
                  <b style={{ color: "black" }}>
                    Your Trusted Partner in Custom Software Solutions
                  </b>
                </p>
              </Col>
            </Row>
          </Col>

          {toggleLogin && (
            <Col md="3" className="p-4 mt-5 pt-5 ">
              <Card className="shadow p-3 mb-5 bg-white rounded mt-5">
                <CardHeader className="text-center pt-2 paupet-header text-white">
                  <p className="mb-0">User Credentials</p>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={handleSubmit}>
                    <FormGroup>
                      <Label for="branch">Branch</Label>
                      <Input
                        type="text"
                        id="branch"
                        value={branch}
                        onChange={(e) => setBranch(e.target.value)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="username">Username</Label>
                      <Input
                        type="text"
                        id="username"
                        value={username}
                        onChange={(e) => setUserName(e.target.value)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="password">Password</Label>
                      <Input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="que">Options</Label>
                      <Input
                        type="select"
                        id="que"
                        value={selectedRoute}
                        onChange={(e) => setSelectedRoute(e.target.value)}
                      >
                        {/* Options for the select dropdown */}
                        <option value="">Select</option>
                        <option key="que" value="que">
                          Que Management
                        </option>
                      </Input>
                    </FormGroup>
                    {loading ? (
                      <Spinner color="primary" />
                    ) : (
                      <Button
                        block
                        style={{
                          backgroundColor:
                            "linear-gradient(to right, indigo, lightblue 50%, white)",
                        }}
                      >
                        Login
                      </Button>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
      </Container>
      <Container>
        <Row>
          <Suspense fallback={<div>Loading PAUPET SOFT...</div>}>
            <PaupetSoft />
          </Suspense>
        </Row>
        <Row>
          <Col>
            <Home />
          </Col>
        </Row>
        <Suspense fallback={<Spinner color="primary" />}>
          <ContactForm />
        </Suspense>

        <Suspense fallback={<Spinner color="primary" />}>
          {modalOpen && (
            <OtpModal
              isOpen={modalOpen}
              toggle={toggleModal}
              handleOtpSubmit={handleOtpSubmit}
            />
          )}
        </Suspense>
      </Container>
    </>
  );
};

export default Login;
