import { useState, useEffect, useRef } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardImg,
  CardImgOverlay,
  CardText,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import validator from "validator";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { getCountries, getCustomers } from "../../../Data/Customer/api";
import ci from "../../../images/cutis_new.jpeg";
import PrintDemography from "./customerForm/printDemography";

const CustomerForm = () => {
  const location = useLocation();
  const authData = location.state;
  const [addressPrint, setAddressPrint] = useState("");
  const auth = useSelector((state) => state.authentication);
  const { userData, branchData } = auth;
  const [username] = useState(JSON.parse(sessionStorage.getItem("username")));
  const [token] = useState(
    authData ? authData.token : JSON.parse(sessionStorage.getItem("token"))
  );
  const branch = sessionStorage.getItem("branch")
    ? JSON.parse(sessionStorage.getItem("branch"))
    : "CLT";

  const [fname, setFname] = useState("");
  const [sname, setSname] = useState("");
  //const [id, setId] = useState("");
  // const [dob, setDOB] = useState("");
  const [printVisible, setPrintVisible] = useState(false);
  const [age, setAge] = useState("");
  const [contactno, setContactno] = useState("");
  const [address, setAddress] = useState("");
  const [nationality, setNationality] = useState("");
  const [locality, setLocality] = useState("");
  const [treatmenttype, setTreatmentType] = useState("PRP");
  const [remarks, setRemarks] = useState("");
  const [patientcoordinator, setPatientCoordinator] = useState("");
  const [dnd, setDND] = useState(false);
  const date = new Date();
  const [MRN, setMRN] = useState("");
  const [verified, setVerified] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchData, setSearchData] = useState("");
  const [modal, setModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [customerData, setCustomerData] = useState("");
  const [appointmentData, setAppointmentData] = useState("");
  const [appointmentCurrent, setAppointmentCurrent] = useState("");
  const toggle = () => setModal(!modal);
  const [modalName, setModalName] = useState("");
  const [sex, setSex] = useState("male");
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [otData, setOTData] = useState("");
  const [emailId, setEmailId] = useState("");
  const [occupation, setOccupation] = useState("");
  const [pastPrescription, setPastPrescription] = useState("");
  const [reactionAlergies, setReactionAlergies] = useState("");
  const [doctorName, setDoctorName] = useState("");
  const [isEmailValid, setEmailValid] = useState(true);
  const [isMobileValid, setIsMobileValid] = useState(true);
  const [saving, setSaving] = useState(false);
  const [togglePastPrescript, settogglePastPrescript] = useState(false);
  const [toggleAllergy, settoggleAllergy] = useState(false);
  const [sourceDetails, setSourceDetails] = useState("");
  const navigate = useNavigate();
  const componentRef = useRef();
  const occupationOptions = [
    "Self Employed",
    "Home Maker",
    "Student",
    "Professional",
  ];
  const sourceOptions = [
    "Reference",
    "Hoardings",
    "Advertisement",
    "Word of mouth",
    "Through a friend/relavive",
    "Instagram",
    "Youtube",
    "Faceebook",
    "Twitter",
    "Whatsapp",
    "Google",
    "X",
  ];
  const reasonforvisitOptions = [
    "Dermatology Consultaion",
    "Cosmetology Consultaion",
    "Hair Problems",
    "Weight Loss / Liposuction",
    "Hair Transplantation",
  ];
  const [countryList, setCountryList] = useState([]);
  const [source, setSource] = useState("");
  const [reasonforVisit, setReasonforVisit] = useState("");
  const datewithoutHours = new Date(date.setHours(0, 0, 0, 0)).toISOString();

  const clearForm = () => {
    setFname("");
    setSname("");
    //const [id, setId] = useState(""
    setContactno("");
    setAddress("");
    setNationality("");
    setLocality("");
    setTreatmentType("");
    setRemarks("");
    setPatientCoordinator("");
    setMRN("");
    setVerified("");
    setErrorMessage("");
    setSearchData("");
    setModal("");
    setEditMode("");
    setCustomerData("");
    setAppointmentData("");
    setAppointmentCurrent("");
    setModalName("");
    setSex("male");
    setSaveSuccess("");
    setEmailId("");
    setOccupation("");
    setPastPrescription("");
    setReactionAlergies("");
    setDoctorName("");
    setAge("");
    setSource("");
    setDND("");
    setReasonforVisit("");
    setSourceDetails("");
    settogglePastPrescript("");
    settoggleAllergy("");
  };

  useEffect(() => {
    if (branchData.length > 0) {
      const { first_line, second_line, third_line, fourth_line } =
        branchData[0].address;

      setAddressPrint({
        first_line,
        second_line,
        third_line,
        fourth_line,
      });
    }
  }, [branchData]);

  useEffect(() => {
    if (localStorage.getItem("savedData")) {
      const storedData = JSON.parse(localStorage.getItem("savedData"));
      if (storedData) {
        setFname(storedData.fname);
        setSname(storedData.sname);
        //const [id, setId] = useState(""
        setAge(storedData.age);
        setContactno(storedData.contactno);
        setAddress(storedData.address);
        setNationality(storedData.nationality);
        setLocality(storedData.locality);
        setTreatmentType(storedData.treatmenttype);
        setRemarks(storedData.remarks);
        setPatientCoordinator(storedData.patientcoordinator);
        setMRN(storedData.mrn);
        setSex(storedData.sex);
        setEmailId(storedData.emailId);
        setOccupation(storedData.occupation);
        setPastPrescription(storedData.pastPrescription);
        setReactionAlergies(storedData.reactionAlergies);
        setDoctorName(storedData.doctorName);
        setSource(storedData.source);
        setDND(storedData.dnd);
        setReasonforVisit(storedData.reasonforVisit);
        setSourceDetails(storedData.sourceDetails);
        settogglePastPrescript(storedData.togglePastPrescript);
        settoggleAllergy(storedData.toggleAllergy);
      }
    }
  }, [localStorage.getItem("savedData")]);

  const toggleEdit = () => {
    //setModal(!modal);
    if (MRN) {
      setEditMode(true);
    }
  };

  const postData = {
    fname,
    sname,
    contactno,
    emailId,
    nationality,
    locality,
    address,
    treatmenttype,
    occupation,
    pastPrescription,
    reactionAlergies,
    remarks,
    patientcoordinator,
    doctorName,
    date: datewithoutHours,
    dob: "",
    sex,
    mrn: "",
    age,
    reasonforVisit,
    source,
    dnd,
    createdBy: username,
    togglePastPrescript,
    toggleAllergy,
    sourceDetails,
  };
  const submitHandler = async () => {
    setSaving(true);
    try {
      localStorage.setItem("savedData", JSON.stringify(postData));
      const response = await axios.post(
        `${process.env.REACT_APP_CRI_API}add-customer`,
        postData,
        {
          params: { branch: branch },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        // Handle successful login
        setSaveSuccess(true);
        alert("Save successful");
        // console.log(response);
        setMRN(response.data.mrn);
        setOTData([
          {
            fname,
            sname,
            contactno,
            address,
            treatmenttype,
            remarks,
            patientcoordinator,
            doctorName,
            date: date,
            dob: "",
            mrn: response.data.mrn,
            age,
          },
        ]);
        localStorage.removeItem("savedData");
      } else {
        // Handle failed login
        // console.log("Invalid credentials");
      }
    } catch (err) {
      if (
        (err.response.data &&
          err.response.data.err.name === "TokenExpiredError") ||
        err.response.data.message === "Unauthorized"
      ) {
        alert("Login Again to Continue");
        navigate("/");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("srchData");
        sessionStorage.removeItem("prescriptionHistory");
        sessionStorage.removeItem("rights");
        navigate("/cri-crm");
        return err;
      }
    }
    setSaving(false);
  };

  useEffect(() => {
    //  console.log(JSON.parse(sessionStorage.getItem("username")));
    //   handleCountry();
    getCountries(token, "", navigate).then((res) => {
      // console.log(res);
      setCountryList(res);
    });
    if (verified) {
      submitHandler();
      setVerified(false);
    }
  }, [verified]);

  const handleSubmit = async () => {
    setSaveSuccess(false);
    setSaving(true);

    if (MRN && editMode) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_CRI_API}edit-customer`,
          {
            fname,
            sname,
            contactno,
            emailId,
            nationality,
            locality,
            address,
            treatmenttype,
            occupation,
            pastPrescription,
            reactionAlergies,
            remarks,
            patientcoordinator,
            doctorName,
            date: datewithoutHours,
            dob: "",
            sex,
            mrn: MRN,
            age,
            reasonforVisit,
            source,
            dnd,
            createdBy: username,
          },
          {
            params: {
              branch: branch,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          // Handle successful login
          alert("Edit successful");
          //   console.log(response.data.mrn);
          //  setMRN(response.data.mrn);
          //    console.log(response);
          getCustomers(token, branch, MRN, navigate).then((res) => {
            //  console.log(res);
            sessionStorage.setItem("srchData", JSON.stringify(res[0]));
          });
        } else {
          // Handle failed login
          alert("Edit failed");
        }
      } catch (err) {
        if (
          (err.response.data &&
            err.response.data.err.name === "TokenExpiredError") ||
          err.response.data.message === "Unauthorized"
        ) {
          alert("Login Again to Continue");
          navigate("/");
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("srchData");
          sessionStorage.removeItem("prescriptionHistory");
          sessionStorage.removeItem("rights");
          navigate("/cri-crm");
          return err;
        }
      }
    } else {
      if (
        fname.length > 2 &&
        contactno.length > 9 &&
        address.length > 3 &&
        date &&
        age
      ) {
        setVerified(true);
      } else {
        alert("Missing data, Kindly enter the details");
      }
    }
    setSaving(false);
  };

  const handlePrintButtonClick = () => {
    // either saved data or searched data in formData

    //setPrintVisible(true);
    return;

    //console.log("Button clicked");
  };

  return (
    <Container>
      <Card className="mt-2 p-5 mb-5">
        <div className="d-flex justify-content-start">
          {userData.branch === "CLT" && (
            <img
              alt="Card image cap"
              style={{ width: "320px" }}
              src={ci}
              //  src="https://picsum.photos/900/270?grayscale"
            />
          )}
        </div>

        <CardHeader
          className=" text-white"
          style={{
            borderRadius: "3px",
            backdropFilter: "blur(10px)",
            WebkitBackdropFilter: "blur(10px)", // For Safari
            backgroundColor: "rgba(50, 0, 100, 0.7)", // Darker purple with opacity
          }}
        >
          <Row>
            <Col md={3} className="mb-0">
              {" "}
            </Col>
            <Col md={6} className="pb-0" style={{ textAlign: "center" }}>
              <h4 className="mb-0">Patient Registration</h4>
            </Col>
            <Col md={3} style={{ textAlign: "right" }} className="pb-0">
              <p>User: {username}</p>
            </Col>
          </Row>
        </CardHeader>

        <CardBody style={{ fontWeight: "bold !important" }}>
          <Row className="d-flex justify-content-end mb-4 mt-3">
            {MRN && <Label sm="1">MR No</Label>}
            {MRN && (
              <Col md="3">
                <Input type="text" readOnly value={MRN} />
              </Col>
            )}

            <Label sm="1">Date :</Label>
            <Col md="2">
              <Input
                readOnly
                type="text"
                value={`${date.getDate()} / ${
                  date.getMonth() + 1
                } /  ${date.getFullYear()} `}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Row>
                <Label className="custom-col">First Name :</Label>
                <Col md="3">
                  <Input
                    type="text"
                    value={fname}
                    readOnly={MRN !== "" && !editMode}
                    onChange={(e) => {
                      setFname(e.target.value);
                    }}
                  />
                </Col>
                <Label className="custom-col">Second Name :</Label>
                <Col md="3">
                  <Input
                    type="text"
                    value={sname}
                    readOnly={MRN !== "" && !editMode}
                    onChange={(e) => {
                      setSname(e.target.value);
                    }}
                  />
                </Col>
                <Col className="d-flex flex-column">
                  <Row>
                    <Label sm="4">Location :</Label>
                    <Col md="8">
                      <Input
                        type="text"
                        value={locality}
                        readOnly={MRN !== "" && !editMode}
                        onChange={(e) => {
                          // (e.target.value);
                          setLocality(e.target.value);
                        }}
                      ></Input>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-4">
                <Label className="custom-col">Contact No:</Label>
                <Col md="3">
                  <Input
                    style={{ borderColor: isMobileValid ? "" : "red" }}
                    type="mobile"
                    value={contactno}
                    readOnly={MRN !== "" && !editMode}
                    onBlur={(e) => {
                      if (e.target.value !== "") {
                        setIsMobileValid(
                          validator.isMobilePhone(e.target.value)
                        );
                      } else {
                        setIsMobileValid(true);
                      }
                    }}
                    onChange={(e) => {
                      setContactno(e.target.value);
                      sessionStorage.removeItem("srchData");
                    }}
                  />
                </Col>
                <Label className="custom-col ">Email ID:</Label>
                <Col md="3">
                  <Input
                    style={{ borderColor: isEmailValid ? "" : "red" }}
                    type="email"
                    value={emailId}
                    readOnly={MRN !== "" && !editMode}
                    onBlur={(e) => {
                      if (e.target.value !== "") {
                        setEmailValid(validator.isEmail(e.target.value));
                      } else {
                        setEmailValid(true);
                      }
                    }}
                    onChange={(e) => {
                      setEmailId(e.target.value);
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Label className="custom-col">Nation:</Label>
                <Col md="3">
                  <Input
                    type="text"
                    list="country"
                    value={nationality}
                    readOnly={MRN !== "" && !editMode}
                    onChange={(e) => {
                      setNationality(e.target.value);
                    }}
                  />
                  <datalist id="country">
                    {countryList.map((item, index) => (
                      <option key={index} value={item.name} />
                    ))}
                  </datalist>
                </Col>
              </Row>
              <Row className="mt-4">
                <Label className="custom-col"> Age:</Label>
                <Col md="1">
                  <Input
                    type="text"
                    value={age}
                    readOnly={MRN !== "" && !editMode}
                    onChange={(e) => {
                      //   console.log(e.target.value);
                      //setDOB(e.target.value);
                      setAge(e.target.value);
                    }}
                  />
                </Col>
                <Label className=" ms-3" style={{ width: "70px" }}>
                  {" "}
                  Male:
                </Label>
                <Col md="1" className="ps-0">
                  <Input
                    type="radio"
                    value={"male"}
                    checked={sex === "male"}
                    readOnly={MRN !== "" && !editMode}
                    onChange={(e) => {
                      // console.log(e.target.value);
                      setSex(e.target.value);
                    }}
                  />
                </Col>
                <Label className="custom-col-r ms-3"> Female:</Label>
                <Col md="1" className="ms-0 ps-0">
                  <Input
                    type="radio"
                    value={"female"}
                    checked={sex === "female"}
                    readOnly={MRN !== "" && !editMode}
                    onChange={(e) => {
                      // console.log(e.target.value);
                      setSex(e.target.value);
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Label className="custom-col"> Occupation:</Label>
                <Col md="3">
                  <Input
                    type="text"
                    list="occupation"
                    value={occupation}
                    readOnly={MRN !== "" && !editMode}
                    onChange={(e) => {
                      setOccupation(e.target.value);
                    }}
                  />
                  <datalist id="occupation">
                    {occupationOptions.map((item, key) => (
                      <option key={key} value={item} />
                    ))}
                  </datalist>
                </Col>
              </Row>
              <Row className="mt-4">
                <Label className="custom-col"> Address:</Label>
                <Col md="8">
                  <Input
                    style={{ width: "94%" }}
                    type="text"
                    value={address}
                    readOnly={MRN !== "" && !editMode}
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                  />
                </Col>
              </Row>

              <Row className="mt-4">
                <Label className="custom-col-l me-4">
                  {" "}
                  Reason for visiting
                </Label>
                <Col md="7">
                  <Input
                    type="text"
                    list="reasonforvisit"
                    value={reasonforVisit}
                    readOnly={MRN !== "" && !editMode}
                    onChange={(e) => {
                      setReasonforVisit(e.target.value);
                    }}
                  />
                  <datalist id="reasonforvisit">
                    {reasonforvisitOptions.map((item, key) => (
                      <option key={key} value={item} />
                    ))}
                  </datalist>
                </Col>
              </Row>
              <Row className="mt-4">
                <Label style={{ width: "350px" }} className="me-4">
                  {" "}
                  Prescription - medicine taken in the past:
                </Label>
                <Col md="2" className="ps-3">
                  <Input
                    name="yesPres"
                    type="radio"
                    value={togglePastPrescript}
                    checked={togglePastPrescript}
                    readOnly={MRN !== "" && !editMode}
                    onChange={(e) => {
                      console.log(e.target.checked);
                      settogglePastPrescript(() => !togglePastPrescript);
                    }}
                  />
                  <Label className=" ms-3 me-2" style={{ width: "40px" }}>
                    {" "}
                    Yes
                  </Label>
                  <Input
                    name="yesPres"
                    type="radio"
                    value={togglePastPrescript}
                    checked={!togglePastPrescript}
                    readOnly={MRN !== "" && !editMode}
                    onChange={(e) => {
                      //console.log(e.target.value);
                      settogglePastPrescript(() => !togglePastPrescript);
                    }}
                    className="ms-2"
                  />
                  <Label className=" ms-3" style={{ width: "40px" }}>
                    {" "}
                    No
                  </Label>
                </Col>
                <Col md="2" className="ms-0 ps-0"></Col>
                <Col md="7">
                  {togglePastPrescript && (
                    <Input
                      type="text"
                      value={pastPrescription}
                      placeholder="If Yes, Please provide details"
                      readOnly={MRN !== "" && !editMode}
                      onChange={(e) => {
                        setPastPrescription(e.target.value);
                      }}
                    />
                  )}
                </Col>
              </Row>
              <Row className="mt-4">
                <Label style={{ width: "390px" }}>
                  {" "}
                  Any reaction or allergies to medicine in the past:
                </Label>
                <Col md="2" className="ps-0">
                  <Input
                    name="toggleAllergy"
                    type="radio"
                    value={toggleAllergy}
                    checked={toggleAllergy}
                    readOnly={MRN !== "" && !editMode}
                    onChange={(e) => {
                      console.log(e.target.checked);
                      settoggleAllergy(() => !toggleAllergy);
                    }}
                  />
                  <Label className=" ms-3" style={{ width: "40px" }}>
                    {" "}
                    Yes
                  </Label>
                  <Input
                    className="ms-3"
                    name="toggleAllergy"
                    type="radio"
                    value={toggleAllergy}
                    checked={!toggleAllergy}
                    readOnly={MRN !== "" && !editMode}
                    onChange={(e) => {
                      //console.log(e.target.value);
                      settoggleAllergy(() => !toggleAllergy);
                    }}
                  />
                  <Label className=" ms-3" style={{ width: "60px" }}>
                    {" "}
                    No
                  </Label>
                </Col>

                <Col md="7">
                  {toggleAllergy && (
                    <Input
                      type="text"
                      value={reactionAlergies}
                      placeholder="If Yes, Please provide details"
                      readOnly={MRN !== "" && !editMode}
                      onChange={(e) => {
                        setReactionAlergies(e.target.value);
                      }}
                    />
                  )}
                </Col>
              </Row>
              <Row className="mt-4 d-flex justify-content-start">
                <Label style={{ width: "600px" }}>
                  {" "}
                  Would you like to recieve the updates from cutis international
                  cosmetic clinic:
                </Label>

                <Col md="3" className="ps-0">
                  <Label style={{ width: "50px" }}> Yes</Label>
                  <Input
                    type="radio"
                    value={"false"}
                    checked={dnd === "false"}
                    readOnly={MRN !== "" && !editMode}
                    onChange={(e) => {
                      // console.log(e.target.value);
                      setDND(e.target.value);
                    }}
                  />
                  <Label
                    style={{ width: "50px", marginLeft: "10px" }}
                    className="ms-5"
                  >
                    {" "}
                    No:
                  </Label>
                  <Input
                    type="radio"
                    value={"true"}
                    checked={dnd === "true"}
                    readOnly={MRN !== "" && !editMode}
                    onChange={(e) => {
                      //console.log(e.target.value);
                      setDND(e.target.value);
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Label className="custom-col"> Remarks:</Label>
                <Col md="4">
                  <Input
                    type="text"
                    value={remarks}
                    readOnly={MRN !== "" && !editMode}
                    onChange={(e) => {
                      setRemarks(e.target.value);
                    }}
                  />
                </Col>
                <Label className="ms-5" style={{ width: "100px" }}>
                  {" "}
                  Source:
                </Label>
                <Col md="2">
                  <Input
                    type="text"
                    list="source"
                    value={source}
                    readOnly={MRN !== "" && !editMode}
                    onChange={(e) => setSource(e.target.value)}
                    placeholder="select or type"
                  />
                  <datalist id="source">
                    {sourceOptions.map((item, key) => (
                      <option key={key} value={item} />
                    ))}
                  </datalist>
                </Col>
                <Col md={2}>
                  <Input
                    type="text"
                    placeholder="Referred by or any details of specific source"
                    onChange={(e) => setSourceDetails(e.target.value)}
                  />
                </Col>
              </Row>
              {saving && <Spinner color="primary" type="grow"></Spinner>}
              {!MRN && !saving && (
                <button
                  onClick={handleSubmit}
                  className="glass-button mt-4 me-2"
                  color="primary"
                >
                  {" "}
                  Submit
                </button>
              )}
              {editMode && MRN && (
                <button onClick={handleSubmit} className="glass-button mt-4">
                  {" "}
                  Update
                </button>
              )}
              {MRN && (
                <>
                  {
                    //    <Button
                    //      onClick={() => {
                    //        const edit = window.confirm("Do you want to edit");
                    //        if (edit) {
                    //          toggleEdit();
                    //        }
                    //      }}
                    //      className="mt-4 bg-primary"
                    //    >
                    //      {" "}
                    //      Edit
                    //    </Button>
                  }
                  <button
                    onClick={clearForm}
                    className=" glass-button mt-4 ms-5 bg-primary"
                  >
                    New Registration
                  </button>
                </>
              )}
              <ReactToPrint
                onBeforePrint={handlePrintButtonClick}
                trigger={() => <button className="glass-button">Print</button>}
                content={() => componentRef.current}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <div style={{ display: "none" }}>
        <PrintDemography
          ref={componentRef}
          data={postData}
          date={date}
          address={addressPrint}
          branch={branch}
        />
      </div>
    </Container>
  );
};

export default CustomerForm;
